import { IdToken, useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { PageLayout } from "../components/page-layout";
import { UserInfo } from "../components/user-info";

export const Home: React.FC = () => {
  const { getIdTokenClaims } = useAuth0();

  const test = async () => {
    const claims: IdToken | undefined = await getIdTokenClaims();
    console.log(
      "claims",
      claims ? claims[process.env.REACT_APP_ROLES_DOMAIN ?? ""] : "null"
    );

    console.log(
      "process.env.REACT_APP_ROLES_DOMAIN",
      process.env.REACT_APP_ROLES_DOMAIN
    );
  };

  return (
    <PageLayout>
      <>
        <Container>
          <Row>
            <Col>
              <h1>Home</h1>
              <p>Login to view GPS information.</p>
            </Col>
          </Row>
        </Container>
      </>
    </PageLayout>
  );
};
