import { GpsLocationModel } from "../models/gps-location-model";

export class GpsLocationService {
  apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

  async listGpsLocations(
    accessToken: string,
    clipboardLocation: string,
    kmDistance: string
  ): Promise<GpsLocationModel[]> {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        clipboardCurrentPosition: clipboardLocation,
        kmDistanceFilter: kmDistance,
      }),
    };

    console.log("requestOptions", requestOptions);

    const response = await fetch(
      `${this.apiServerUrl}/api/gps-location/locations`,
      requestOptions
    );

    var jsonData = await response.json();
    return jsonData as GpsLocationModel[];
  }
}
