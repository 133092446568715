import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { PageLayout } from "../../components/page-layout";
import { SeUserModel } from "../../models/se-user-model";
import { SeUserService } from "../../services/se-user-service";

export const AdminHome: React.FC = () => {
  const [seUsers, setGPSUsers] = useState<SeUserModel[]>([]);
  const [accessToken, setAccessToken] = useState<string>("");

  const { getAccessTokenSilently } = useAuth0();

  const seUserService = new SeUserService();

  useEffect(() => {
    const getSeUsers = async () => {
      var token = await getAccessTokenSilently();
      setAccessToken(token);

      const results = await seUserService.listAllSeUsers(token);
      setGPSUsers(results);
    };

    getSeUsers();
  }, [setGPSUsers, setAccessToken, getAccessTokenSilently]);

  function handleSandboxIdChange(
    seUserKey: string | undefined,
    sandboxId: string
  ) {
    const gpsUsersCopy = [...seUsers];
    for (let i = 0; i < gpsUsersCopy.length; i++) {
      if (seUserKey === gpsUsersCopy[i].seUserKey) {
        gpsUsersCopy[i].sandboxId = sandboxId;
        break;
      }
    }

    setGPSUsers(gpsUsersCopy);
  }

  async function handleSaveChangesOnClick() {
    await seUserService.updateSeUserList(accessToken, seUsers);
  }

  return (
    <PageLayout>
      <>
        <Row>
          <Col xs={2}>
            <p>Admin Home</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Auth0Id</th>
                  <th>Email</th>
                  <th>Nickname</th>
                  <th>SandboxId</th>
                </tr>
              </thead>
              <tbody>
                {seUsers &&
                  seUsers.map((user) => (
                    <tr key={user.seUserKey}>
                      <td>
                        {user.picture && (
                          <img
                            className="rounded"
                            src={user.picture}
                            style={{ height: "25px" }}
                          />
                        )}
                      </td>
                      <td>{user.auth0Id}</td>
                      <td>{user.email}</td>
                      <td>{user.nickname}</td>
                      <td>
                        <input
                          type="text"
                          value={user.sandboxId ?? ""}
                          onChange={(e) =>
                            handleSandboxIdChange(
                              user.seUserKey,
                              e.target.value
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Button variant="primary" onClick={handleSaveChangesOnClick}>
              Update List
            </Button>
          </Col>
        </Row>
        <Row>
          <div>
            <h4>Sandbox Ids</h4>
            <ul>
              {seUsers &&
                seUsers.map((user) => (
                  <li key={user.seUserKey}>{user.sandboxId}</li>
                ))}
            </ul>
          </div>
        </Row>
      </>
    </PageLayout>
  );
};
