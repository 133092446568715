import React from "react";
import { PageLayout } from "../components/page-layout";

export const About: React.FC = () => {
  return (
    <PageLayout>
      <>
        <p>About</p>
      </>
    </PageLayout>
  );
};
