import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NavigationBar = () => {
  const [idToken, setIdToken] = useState<IdToken | undefined>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { getIdTokenClaims } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  const { logout } = useAuth0();
  const { isAuthenticated } = useAuth0();
  const { user } = useAuth0();

  useEffect(() => {
    getIdTokenClaims().then((idToken) => {
      if (idToken) {
        const isAdmin = idToken[
          process.env.REACT_APP_ROLES_DOMAIN ?? ""
        ]?.includes(process.env.REACT_APP_ADMIN_ROLE);
        setIsAdmin(isAdmin);
      }
      setIdToken(idToken);
    });

    setIsLoading(false);
  }, [isLoading, setIsLoading, getIdTokenClaims, setIdToken, setIsAdmin]);

  const hanldeLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
    });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">SE GPS</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {!isLoading && (
              <>
                <Nav.Link href="/">Home</Nav.Link>
                {isAuthenticated && <Nav.Link href="/gps">GPS</Nav.Link>}
                {isAdmin && (
                  <NavDropdown title="Admin" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/admin/admin-home">
                      Manage Users
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </>
            )}
          </Nav>
          <Nav>
            {isAuthenticated && (
              <>
                <Image
                  roundedCircle={true}
                  src={user?.picture}
                  style={{ height: "50px" }}
                  className="d-none d-lg-block"
                />
                <NavDropdown
                  align="end"
                  title={
                    <>
                      <FontAwesomeIcon icon={faGear} className="" />
                    </>
                  }
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item onClick={handleLogout}>
                    Log Out
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
            {!isAuthenticated && (
              <Nav.Link onClick={hanldeLogin}>Log In</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

  return (
    <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
      <Navbar.Toggle
        aria-controls="navbarScroll"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
      />
      <Navbar.Collapse id="navbarScroll">
        <Nav>
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/about">About</Nav.Link>
          <Nav.Link href="/gps"></Nav.Link>
          <Nav.Link href="/admin/admin-home">Admin</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );

  return (
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/gps">GPS</Link>
      </li>
      <li>
        <Link to="/admin/admin-home">Admin</Link>
      </li>
      <li>
        {!isAuthenticated && <a onClick={hanldeLogin}>Log In</a>}
        {isAuthenticated && <a onClick={handleLogout}>Log Out</a>}
      </li>
    </ul>
  );
};
