import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { PageLayout } from "../components/page-layout";
import { GpsLocationModel } from "../models/gps-location-model";
import { GpsLocationService } from "../services/gps-location-service";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GpsPlotly3dPlotting } from "../components/gps-plotly-3d-plotting";

export const GPS: React.FC = () => {
  const [gpsLocationModels, setGPSLocationModels] = useState<
    GpsLocationModel[]
  >([]);
  const [filteredGpsLocationModels, setFilteredGpsLocationModels] = useState<
    GpsLocationModel[]
  >([]);
  const [filterByNameValue, setFilterByNameValue] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [gpsLocationForm, setClipboardLocation] = useState({
    clipboardLocation: "", // GPS:cob/iron:15039.3:223735.34:5758098.61:#FF75C9F1:
    kmDistanceFilter: "",
  });
  const [sortOrder, setSortOrder] = useState<string>("up");

  const { getAccessTokenSilently } = useAuth0();

  const gpsLocationService = new GpsLocationService();

  const clipboardKey = "key-clipboardLocation";
  const kmFilterKey = "key-kmFilter";

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => setAccessToken(accessToken));
    if (localStorage.getItem(clipboardKey)) {
      gpsLocationForm.clipboardLocation =
        localStorage.getItem(clipboardKey) ?? "";
      gpsLocationForm.kmDistanceFilter =
        localStorage.getItem(kmFilterKey) ?? "";
      setClipboardLocation(gpsLocationForm);
      setSortOrder("up");
    }
  }, [
    setAccessToken,
    getAccessTokenSilently,
    setClipboardLocation,
    setSortOrder,
  ]);

  function handleClipboardLocationChange(input: typeof gpsLocationForm) {
    console.log(input);
    setClipboardLocation(input);
  }

  function handleFilterOnChange(input: string) {
    setFilterByNameValue(input);
    filterGpsModels(gpsLocationModels, input);
    sortModels(gpsLocationModels);
  }

  function handleGpsLocationsClick() {
    gpsLocationService
      .listGpsLocations(
        accessToken,
        gpsLocationForm.clipboardLocation,
        gpsLocationForm.kmDistanceFilter
      )
      .then((results) => {
        localStorage.setItem(clipboardKey, gpsLocationForm.clipboardLocation);
        localStorage.setItem(kmFilterKey, gpsLocationForm.kmDistanceFilter);
        setGPSLocationModels(results);
        filterGpsModels(results, filterByNameValue);
        sortModels(results);
      });
  }

  function filterGpsModels(gpsModels: GpsLocationModel[], filterValue: string) {
    if (filterByNameValue.length > 0) {
      const filteredModels: GpsLocationModel[] = [];
      for (let i = 0; i < gpsModels.length; i++) {
        const gpsName = gpsModels[i].gpsName?.toLocaleLowerCase();
        const indexLocation = gpsName?.indexOf(filterByNameValue) ?? -1;
        if (indexLocation > -1) {
          filteredModels.push(gpsModels[i]);
        }
      }
      setFilteredGpsLocationModels(filteredModels);
    } else {
      setFilteredGpsLocationModels(gpsModels);
    }
  }

  function handleSortClick() {
    if (sortOrder === "down") {
      setSortOrder("up");
    } else {
      setSortOrder("down");
    }

    sortModels(filteredGpsLocationModels);
  }

  function sortModels(gpsLocationModels: GpsLocationModel[]) {
    console.log("sortModels", sortOrder);
    let sortedModels = [...gpsLocationModels];
    sortedModels.sort((a, b) => {
      if (a.distanceInKm < b.distanceInKm) {
        return sortOrder === "down" ? 1 : -1;
      }
      if (a.distanceInKm > b.distanceInKm) {
        return sortOrder === "down" ? -1 : 1;
      }
      return 0;
    });

    setFilteredGpsLocationModels(sortedModels);
  }

  return (
    <PageLayout>
      <>
        <Row>
          <Col>
            <h1>GPS Locations</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <>
              <Row>
                <p>
                  Enter the clipboard GPS location. {"("}
                  <i>
                    Example:
                    GPS:cob/iron:15039.3:223735.34:5758098.61:#FF75C9F1:
                  </i>
                  {")"}
                </p>
                <p></p>
              </Row>
              <div className="row g-3">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Clipboard GPS location"
                    value={gpsLocationForm.clipboardLocation}
                    onChange={(e) =>
                      handleClipboardLocationChange({
                        ...gpsLocationForm,
                        clipboardLocation: e.target.value,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="km"
                    value={gpsLocationForm.kmDistanceFilter}
                    onChange={(e) =>
                      handleClipboardLocationChange({
                        ...gpsLocationForm,
                        kmDistanceFilter: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col">
                  <Button variant="primary" onClick={handleGpsLocationsClick}>
                    Refresh
                  </Button>
                </div>
              </div>
            </>
            <br />
          </Col>
        </Row>
        <div className="row g-3">
          <div className="row row-cols-lg-auto g-3 align-items-center">
            <div className="col-5">
              <div className="input-group">
                <div className="input-group-text">Filter By Name:</div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="name"
                  onChange={(e) => handleFilterOnChange(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <GpsPlotly3dPlotting Data={filteredGpsLocationModels} />
        <br />
        <Row>
          <Col>
            {gpsLocationModels && (
              <>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th onClick={handleSortClick}>
                        Distance
                        <span style={{ paddingLeft: 10 }}>
                          {sortOrder == "down" && (
                            <FontAwesomeIcon icon={faSortDown} />
                          )}
                          {sortOrder != "down" && (
                            <FontAwesomeIcon icon={faSortUp} />
                          )}
                        </span>
                      </th>
                      <th>X</th>
                      <th>Y</th>
                      <th>Z</th>
                      <th>Color</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredGpsLocationModels &&
                      filteredGpsLocationModels.map((gps) => (
                        <tr key={gps.key}>
                          <td>{gps.gpsName}</td>
                          <td>{gps.friendlyDistanceInKm}</td>
                          <td>{gps.xPos}</td>
                          <td>{gps.yPos}</td>
                          <td>{gps.zPos}</td>
                          <td
                            style={{ backgroundColor: `#${gps.hexColor}` }}
                          ></td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </>
            )}
          </Col>
        </Row>
      </>
    </PageLayout>
  );
};
