import React from "react";
import { Container } from "react-bootstrap";
import { NavigationBar } from "./navigation/navigation-bar";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <NavigationBar />
      <Container>{children}</Container>;
    </div>
  );
};
