import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Plot from "react-plotly.js";
import { GpsLocationModel } from "../models/gps-location-model";

interface Props {
  Data: GpsLocationModel[];
}

export const GpsPlotly3dPlotting = (props: Props) => {
  if (props.Data.length < 1) {
    return <div></div>;
  }
  console.log(props.Data);

  let gps: GpsLocationModel[] = new Array<GpsLocationModel>();
  let planets: GpsLocationModel[] = new Array<GpsLocationModel>();
  let referencePoint: GpsLocationModel[] = new Array<GpsLocationModel>();

  for (var i = 0; i < props.Data.length; i++) {
    switch (props.Data[i].locationType) {
      case 1: {
        gps.push(props.Data[i]);
        break;
      }
      case 2: {
        planets.push(props.Data[i]);
        break;
      }
      case 5: {
        referencePoint.push(props.Data[i]);
        break;
      }
      default: {
        break;
      }
    }
  }

  return (
    <Plot
      data={[
        {
          name: "GPS Locations",
          text: gps.map((gpsLocationModel) => {
            return gpsLocationModel.gpsName;
          }),
          x: gps.map((gpsLocationModel) => {
            return gpsLocationModel.xPos;
          }),
          y: gps.map((gpsLocationModel) => {
            return gpsLocationModel.yPos;
          }),
          z: gps.map((gpsLocationModel) => {
            return gpsLocationModel.zPos;
          }),
          type: "scatter3d",
          mode: "text+markers",
          marker: {
            color: gps.map((gpsLocationModel) => {
              return (
                "rgb(" +
                gpsLocationModel.colorR +
                "," +
                gpsLocationModel.colorG +
                "," +
                gpsLocationModel.colorB +
                ")"
              );
            }),
            size: 10,
          },
        },
        {
          name: "Planet and Moon Locations",
          text: planets.map((gpsLocationModel) => {
            return gpsLocationModel.gpsName;
          }),
          x: planets.map((gpsLocationModel) => {
            return gpsLocationModel.xPos;
          }),
          y: planets.map((gpsLocationModel) => {
            return gpsLocationModel.yPos;
          }),
          z: planets.map((gpsLocationModel) => {
            return gpsLocationModel.zPos;
          }),
          type: "scatter3d",
          mode: "text+markers",
          marker: {
            color: planets.map((gpsLocationModel) => {
              return (
                "rgb(" +
                gpsLocationModel.colorR +
                "," +
                gpsLocationModel.colorG +
                "," +
                gpsLocationModel.colorB +
                ")"
              );
            }),
            size: 15,
            line: {
              width: 12,
              color: "black",
            },
          },
        },
        {
          name: "Reference Point",
          text: referencePoint.map((gpsLocationModel) => {
            return gpsLocationModel.gpsName;
          }),
          x: referencePoint.map((gpsLocationModel) => {
            return gpsLocationModel.xPos;
          }),
          y: referencePoint.map((gpsLocationModel) => {
            return gpsLocationModel.yPos;
          }),
          z: referencePoint.map((gpsLocationModel) => {
            return gpsLocationModel.zPos;
          }),
          type: "scatter3d",
          mode: "text+markers",
          marker: {
            color: "black",
            size: 5,
            line: {
              width: 12,
              color: "Yellow",
            },
          },
        },
        { type: "scatter3d" },
      ]}
      layout={{
        title: "",
        autosize: true,
        width: 1200,
        height: 600,
        scene: {
          aspectratio: {
            x: 1,
            y: 1,
            z: 1,
          },
          camera: {
            center: {
              x: 0,
              y: 0,
              z: 0,
            },
            eye: {
              x: 1.25,
              y: 1.25,
              z: 1.25,
            },
            up: {
              x: 0,
              y: 0,
              z: 1,
            },
          },
          xaxis: {
            type: "linear",
            zeroline: false,
          },
          yaxis: {
            type: "linear",
            zeroline: false,
          },
          zaxis: {
            type: "linear",
            zeroline: false,
          },
        },
      }}
    />
  );
};
