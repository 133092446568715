import { useAuth0 } from "@auth0/auth0-react";
import { Route } from "react-router";
import { BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import { AuthenticationGuard } from "./components/authentication-guard";
import { PageLoader } from "./components/page-loader";
import { About } from "./pages/about";
import { AdminHome } from "./pages/admin/admin-home";
import { GPS } from "./pages/gps";
import { Home } from "./pages/home";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}>
          Home
        </Route>
        <Route path="/about" element={<About />}>
          About
        </Route>
        <Route path="/gps" element={<GPS />}>
          GPS
        </Route>
        <Route
          path="/admin/admin-home"
          element={<AuthenticationGuard component={AdminHome} />}
        >
          Admin
        </Route>
      </Routes>
    </div>
  );
}

export default App;
