import { SeUserModel } from "../models/se-user-model";

export class SeUserService {
  apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

  async listAllSeUsers(accessToken: string): Promise<SeUserModel[]> {
    const response = await fetch(`${this.apiServerUrl}/api/se-user/list-all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    var jsonData = await response.json();
    var results = jsonData as SeUserModel[];

    console.log("jsonData", results);

    return results;
  }

  async updateSeUserList(
    accessToken: string,
    models: SeUserModel[]
  ): Promise<boolean> {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(models),
    };

    const response = await fetch(
      `${this.apiServerUrl}/api/se-user/update-list`,
      requestOptions
    );

    var jsonData = await response.json();
    return jsonData as boolean;
  }
}
